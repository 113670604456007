export class Resource {
  private _id: number;
  private _icon: string;
  private _title: string;

  static tableName = 'resources';

  constructor(resource?) {
    this.id = resource.id;
    this.icon = resource.icon;
    this.title = resource.title;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get icon(): string {
    return this._icon;
  }

  public set icon(icon: string) {
    this._icon = icon;
  }

  public get title(): string {
    return this._title;
  }

  public set title(title: string) {
    this._title = title;
  }

  /**
   * get value
   */
  public get values(): object {
    let formData = new FormData();
    let object = {};
    const properties = ['id', 'title', 'icon'];
    properties.forEach((item) => {
      if (this[item]) {
        formData.append(item, this[item]);
        // object[item] = this[item];
      }
    });
    // return object;
    return formData;
  }
}
