import { UniversityStatus, GeographicalLocation } from '../enums';
import { TrainingOffer } from './trainingOffer';
import { Lesson } from './lesson';
import { Establishment } from './establishment';
import { ContactIcm } from './contactIcm';
import { ContactCbhe } from './contactCbhe';
import { ContactJeanMonnet } from './contactJeanMonnet';
import { ContactEmjmd } from './contactEmjmd';
import { InternationalRelation } from './internationalRelation';

export class University {
  private _id: number;
  private _universityNumber: number;
  private _name: string;
  private _status: UniversityStatus;
  private _geographicalLocalisation: GeographicalLocation;
  private _address: string;
  private _logo: string;
  private _webSite: string;
  private _facebook: string;
  private _instagram: string;
  private _linkedin: string;
  private _numberOfStudents: number;
  private _numberOfInternationalRelations: number;
  private _numberOfTeachers: number;
  private _numberOfStaff: number;
  private _numberOfErasmusStudents: number;
  private _numberOfErasmusTeachers: number;
  private _trainigOffer: TrainingOffer[];
  private _lessons: Lesson[];
  private _establishment: Establishment[];
  private _contactIcm: ContactIcm[];
  private _contactCbhe: ContactCbhe[];
  private _contactJeanMonnet: ContactJeanMonnet[];
  private _contactEmjmd: ContactEmjmd[];
  private _internationalRelations: InternationalRelation[];
  static tableName = 'universities';

  constructor(university?: any) {
    this.id = university.id;
    this.universityNumber = university.universityNumber;
    this.name = university.name;
    this.status = university.status;
    this.geographicalLocalisation = university.geographicalLocalisation;
    this.address = university.address;
    this.logo = university.logo;
    this.webSite = university.webSite;
    this.facebook = university.facebook;
    this.instagram = university.instagram;
    this.linkedin = university.linkedin;
    this.numberOfStudents = university.numberOfStudents;
    this.numberOfErasmusStudents = university.numberOfErasmusStudents;
    this.numberOfInternationalRelations =
      university.numberOfInternationalRelations;
    this.numberOfTeachers = university.numberOfTeachers;
    this.numberOfStaff = university.numberOfStaff;
    this.numberOfErasmusTeachers = university.numberOfErasmusTeachers;
    this.trainigOffer = university.trainigOffer;
    this.lessons = university.lessons;
    this.establishment = university.establishment;
    this.contactIcm = university.contactIcm;
    this.contactCbhe = university.ContactCbhe;
    this.contactJeanMonnet = university.contactJeanMonnet;
    this.contactEmjmd = university.contactEmjmd;
    this.internationalRelations = university.internationalRelations;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get universityNumber(): number {
    return this._universityNumber;
  }

  public set universityNumber(universityNumber: number) {
    this._universityNumber = universityNumber;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get status(): UniversityStatus {
    return this._status;
  }

  public set status(status: UniversityStatus) {
    this._status = status;
  }

  public get geographicalLocalisation(): GeographicalLocation {
    return this._geographicalLocalisation;
  }

  public set geographicalLocalisation(
    geographicalLocation: GeographicalLocation
  ) {
    this._geographicalLocalisation = geographicalLocation;
  }

  public get address(): string {
    return this._address;
  }

  public set address(address: string) {
    this._address = address;
  }
  public get logo(): string {
    return this._logo;
  }

  public set logo(logo: string) {
    this._logo = logo;
  }

  public get webSite(): string {
    return this._webSite;
  }

  public set webSite(webSite: string) {
    this._webSite = webSite;
  }

  public get facebook(): string {
    return this._facebook;
  }

  public set facebook(facebook: string) {
    this._facebook = facebook;
  }

  public get instagram(): string {
    return this._instagram;
  }

  public set instagram(instagram: string) {
    this._instagram = instagram;
  }

  public get linkedin(): string {
    return this._linkedin;
  }

  public set linkedin(linkedin: string) {
    this._linkedin = linkedin;
  }

  public get numberOfStudents(): number {
    return this._numberOfStudents;
  }

  public set numberOfStudents(numberOfStudents: number) {
    this._numberOfStudents = numberOfStudents;
  }

  public get numberOfInternationalRelations(): number {
    return this._numberOfInternationalRelations;
  }

  public set numberOfInternationalRelations(
    numberOfInternationalRelations: number
  ) {
    this._numberOfInternationalRelations = numberOfInternationalRelations;
  }

  public get numberOfTeachers(): number {
    return this._numberOfTeachers;
  }

  public set numberOfTeachers(numberOfTeachers: number) {
    this._numberOfTeachers = numberOfTeachers;
  }

  public get numberOfStaff(): number {
    return this._numberOfStaff;
  }

  public set numberOfStaff(numberOfStaff: number) {
    this._numberOfStaff = numberOfStaff;
  }

  public get numberOfErasmusStudents(): number {
    return this._numberOfErasmusStudents;
  }

  public set numberOfErasmusStudents(numberOfErasmusStudents: number) {
    this._numberOfErasmusStudents = numberOfErasmusStudents;
  }

  public get numberOfErasmusTeachers(): number {
    return this._numberOfErasmusTeachers;
  }

  public set numberOfErasmusTeachers(numberOfErasmusTeachers: number) {
    this._numberOfErasmusTeachers = numberOfErasmusTeachers;
  }

  public get trainigOffer(): TrainingOffer[] {
    return this._trainigOffer;
  }

  public set trainigOffer(trainigOffer: TrainingOffer[]) {
    this._trainigOffer = trainigOffer;
  }

  public get lessons(): Lesson[] {
    return this._lessons;
  }

  public set lessons(lessons: Lesson[]) {
    this._lessons = lessons;
  }

  public get establishment(): Establishment[] {
    return this._establishment;
  }

  public set establishment(establishment: Establishment[]) {
    this._establishment = establishment;
  }

  public get contactIcm(): ContactIcm[] {
    return this._contactIcm;
  }

  public set contactIcm(contactIcm: ContactIcm[]) {
    this._contactIcm = contactIcm;
  }

  public get contactCbhe(): ContactCbhe[] {
    return this._contactCbhe;
  }

  public set contactCbhe(contactCbhe: ContactCbhe[]) {
    this._contactCbhe = contactCbhe;
  }

  public get contactJeanMonnet(): ContactJeanMonnet[] {
    return this._contactJeanMonnet;
  }

  public set contactJeanMonnet(contactJeanMonnet: ContactJeanMonnet[]) {
    this._contactJeanMonnet = contactJeanMonnet;
  }

  public get contactEmjmd(): ContactEmjmd[] {
    return this._contactEmjmd;
  }

  public set contactEmjmd(contactEmjmd: ContactEmjmd[]) {
    this._contactEmjmd = contactEmjmd;
  }

  public get internationalRelations(): InternationalRelation[] {
    return this._internationalRelations;
  }

  public set internationalRelations(
    internationalRelations: InternationalRelation[]
  ) {
    this._internationalRelations = internationalRelations;
  }
  /**
   * get value
   */
  public get values(): object {
    let formData = new FormData();
    // let object = {};
    const properties = [
      'id',
      'universityNumber',
      'name',
      'status',
      'geographicalLocalisation',
      'address',
      'logo',
      'webSite',
      'facebook',
      'instagram',
      'linkedin',
      'numberOfStudents',
      'numberOfInternationalRelations',
      'numberOfErasmusStudents',
      'numberOfTeachers',
      'numberOfStaff',
      'numberOfErasmusTeachers',
      'trainigOffer',
      'lessons',
      'establishment',
      'contactIcm',
      'contactCbhe',
      'contactJeanMonnet',
      'contactEmjmd',
      'internationalRelations',
    ];
    properties.forEach((item) => {
      if (this[item]) {
        formData.append(item, this[item]);
        // object[item] = this[item];
      }
    });
    // return object;
    return formData;
  }
}
