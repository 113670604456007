import { Right } from './right';
import { Helper } from '../../utils';

export class Role {

    private _id: number;
    private _name: string;
    private _rights: Right[] | number[];
    static tableName = 'auth/roles'

    constructor(role?: any) {
        this.id = role.id;
        this.name = role.name;
        this.rights = role.rights;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get rights(): Right[] | number[] {
        return this._rights;
    }

    public set rights(rights: Right[] | number[]) {
        this._rights = rights;
    }

    /**
     * get value
     */
    public get values(): object {
        return Helper.getObjectValues(this, ['id', 'name', 'rights']);
    }

}