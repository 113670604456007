import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { University } from '../models/classes/university';
import { ApiService } from './global/api.service';

@Injectable({
  providedIn: 'root',
})
export class UniversityService {
  activeUniversityId = new BehaviorSubject(undefined);
  constructor(private apiService: ApiService) {}
  getActiveUniversityId() {
    return this.activeUniversityId as Observable<any>;
  }
  setActiveUniversityId(id) {
    this.activeUniversityId.next(id);
  }
  getAllUniversities() {
    return this.apiService.get(University.tableName).pipe(
      shareReplay(1),
      map((data) => {
        return data.data;
      })
    );
  }
}
