export abstract class Training {
  protected _id: number;
  protected _establishment: string;
  protected _diploma: string;
  private _university_id: number;

  constructor(training?: any) {
    this.id = training.id;
    this.establishment = training.establishment;
    this.diploma = training.diploma;
    this.university_id = training.university_id;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get establishment(): string {
    return this._establishment;
  }

  public set establishment(establishment: string) {
    this._establishment = establishment;
  }

  public get diploma(): string {
    return this._diploma;
  }

  public set diploma(diploma: string) {
    this._diploma = diploma;
  }

  public get university_id(): number {
    return this._university_id;
  }

  public set university_id(university_id: number) {
    this._university_id = university_id;
  }

  /**
   * get value
   */
  public get values(): object {
    let formData = new FormData();
    let object = {};
    const properties = ['id', 'establishment', 'diploma', 'university_id'];
    properties.forEach((item) => {
      if (this[item]) {
        formData.append(item, this[item]);
        object[item] = this[item];
      }
    });
    return object;
    // return formData;
  }
}
