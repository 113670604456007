export class ExternalPage {
  private _id: number;
  private _user_id: number;
  private _label: string;
  private _link: string;
  private _publish: boolean;

  static tableName = 'external-pages';

  constructor(page?: any) {
    this.id = page.id;
    this.label = page.label;
    this.link = page.link;
    this.publish = page.publish;
    this.user_id = page.user_id;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get label(): string {
    return this._label;
  }

  public set label(label: string) {
    this._label = label;
  }

  public get link(): string {
    return this._link;
  }

  public set link(link: string) {
    this._link = link;
  }

  public get publish(): boolean {
    return this._publish;
  }

  public set publish(publish: boolean) {
    this._publish = publish;
  }

  public get user_id(): number {
    return this._user_id;
  }

  public set user_id(user_id: number) {
    this._user_id = user_id;
  }

  /**
   * get value
   */
  public get values(): object {
    let object = {};
    const properties = ['id', 'label', 'link', 'publish', 'user_id'];
    properties.forEach((item) => {
      if (this[item]) {
        object[item] = this[item];
      }
    });
    return object;
  }
}
