import { ContactFunction } from '../enums';

export abstract class ContactProject {
  private _id: number;
  private _fullName: string;
  private _phoneNumber: string;
  private _email: string;
  private _contactFunction: ContactFunction;
  private _university_id: number;

  constructor(contactProject?: any) {
    this.id = contactProject.id;
    this.fullName = contactProject.fullName;
    this.phoneNumber = contactProject.phoneNumber;
    this.email = contactProject.email;
    this.contactFunction = contactProject.contactFunction;
    this.university_id = contactProject.university_id;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get fullName(): string {
    return this._fullName;
  }

  public set fullName(fullName: string) {
    this._fullName = fullName;
  }

  public get phoneNumber(): string {
    return this._phoneNumber;
  }

  public set phoneNumber(phoneNumber: string) {
    this._phoneNumber = phoneNumber;
  }
  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get contactFunction(): ContactFunction {
    return this._contactFunction;
  }

  public set contactFunction(contactFunction: ContactFunction) {
    this._contactFunction = contactFunction;
  }
  public get university_id(): number {
    return this._university_id;
  }

  public set university_id(university_id: number) {
    this._university_id = university_id;
  }

  /**
   * get value
   */
  public get values(): object {
    let formData = new FormData();
    let object = {};
    const properties = [
      'id',
      'fullName',
      'phoneNumber',
      'email',
      'contactFunction',
      'university_id',
    ];
    properties.forEach((item) => {
      if (this[item]) {
        formData.append(item, this[item]);
        object[item] = this[item];
      }
    });
    return object;
    // return formData;
  }
}
