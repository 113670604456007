export class Page {
  private _id: number;
  private _title: string;
  private _label: string;
  private _slug: string;
  private _body: string;
  private _user_id: number;
  private _isSystem: boolean;
  private _publish: boolean;

  static tableName = 'pages';

  constructor(page?: any) {
    this.id = page.id;
    this.title = page.title;
    this.label = page.label;
    this.slug = page.slug;
    this.body = page.body;
    this.isSystem = page.isSystem;
    this.publish = page.publish;
    this.user_id = page.user_id;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get title(): string {
    return this._title;
  }

  public set title(title: string) {
    this._title = title;
  }

  public get label(): string {
    return this._label;
  }

  public set label(label: string) {
    this._label = label;
  }

  public get slug(): string {
    return this._slug;
  }

  public set slug(slug: string) {
    this._slug = slug;
  }

  public get body(): string {
    return this._body;
  }

  public set body(body: string) {
    this._body = body;
  }

  public get isSystem(): boolean {
    return this._isSystem;
  }

  public set isSystem(isSystem: boolean) {
    this._isSystem = isSystem;
  }

  public get publish(): boolean {
    return this._publish;
  }

  public set publish(publish: boolean) {
    this._publish = publish;
  }
  public get user_id(): number {
    return this._user_id;
  }

  public set user_id(user_id: number) {
    this._user_id = user_id;
  }

  /**
   * get value
   */
  public get values(): object {
    let object = {};
    const properties = [
      'id',
      'title',
      'label',
      'slug',
      'body',
      'isSystem',
      'publish',
      'user_id',
    ];
    properties.forEach((item) => {
      if (this[item]) {
        object[item] = this[item];
      }
    });
    return object;
  }
}
