import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { University } from '../models/classes/university';
import { ApiService } from './global/api.service';

@Injectable({
  providedIn: 'root',
})
export class IcmToolsProjectService {
  activeItemId = new BehaviorSubject(undefined);
  constructor() {}
  getActiveItemId() {
    return this.activeItemId as Observable<any>;
  }
  setActiveItemId(id) {
    this.activeItemId.next(id);
  }
}
